import { defineStore } from "pinia";
import axios from "axios";

export const useAuthStore = defineStore("auth", {
  state: () => ({
    authenticated: false,
    user: null,
    switchClients: false,
  }),
  persist: true,
  getters: {
    availableCampaigns: (state) => {
      if (state.user == null) {
        return [];
      }
      return state.user.client.campaigns;
    },
  },
  actions: {
    async signIn(credentials) {
      await axios({
        url: "/sanctum/csrf-cookie",
        baseURL: process.env.VUE_APP_SANCTUM_BASE_URL,
        method: "get",
      });
      await axios({
        url: "/api/v1/auth/login",
        data: credentials,
        baseURL: process.env.VUE_APP_SANCTUM_BASE_URL,
        method: "post",
      });
      return this.me();
    },
    async signOut() {
      await axios({
        url: "/auth/logout",
        method: "post",
      }).then(() => {
        this.$reset();
        return this.me();
      });
    },
    resetState() {
      this.$reset();
    },
    async me() {
      return axios({
        url: "/user",
        baseURL: process.env.VUE_APP_API_BASE_URL,
        method: "get",
      })
        .then((response) => {
          //console.log("setting authenticated true");
          this.authenticated = true;
          this.user = response.data;
        })
        .catch(() => {
          this.authenticated = false;
          this.user = null;
        });
    },
  },
});
