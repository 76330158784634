import { createRouter, createWebHashHistory } from "vue-router";
import { useAuthStore } from "@/stores/auth";
import { useAppStore } from "@/stores/app";

const routes = [
  {
    path: "/",
    name: "dashboard",
    exact: true,
    component: () => import("@/views/CampaignOverview.vue"),
    meta: {
      requiresAuth: true,
      requiresCampaign: true,
    },
  },
  {
    path: "/admin/super/secret/dashboard",
    name: "master-dashboard",
    exact: true,
    component: () => import("@/views/admin/MasterCampaignDashboard.vue"),
    meta: {
      requiresAuth: true,
      requiresCampaign: true,
    },
  },
  {
    path: "/campaign-overview/mailing/:mailing_id/response-dashboard",
    name: "response-dashboard",
    exact: true,
    component: () => import("@/views/ResponseDashboard"),
    meta: {
      requiresAuth: true,
      requiresCampaign: true,
    },
  },
  {
    path: "/select-campaign",
    name: "select-campaign",
    component: () => import("@/views/CampaignSelector"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/campaign-overview/mailing/:id",
    name: "mailing-overview",
    exact: true,
    component: () => import("@/views/MailingOverview"),
    meta: {
      requiresAuth: true,
      requiresCampaign: true,
    },
  },
  {
    path: "/mailing/:id/delivery_detail",
    name: "delivery-detail",
    exact: true,
    component: () => import("@/views/DeliveryDetail"),
    meta: {
      requiresAuth: true,
      requiresCampaign: true,
    },
  },
  {
    path: "/campaign-dashboard",
    name: "campaign-dashboard",
    exact: true,
    component: () => import("@/views/CampaignDashboard.vue"),
    meta: {
      requiresAuth: true,
      requiresCampaign: true,
    },
  },
  {
    path: "/responders/:selected_mailing_id?",
    name: "responders",
    exact: true,
    component: () => import("@/views/Responders.vue"),
    meta: {
      requiresAuth: true,
      requiresCampaign: true,
    },
  },
  {
    path: "/form-conversions",
    name: "form-conversions",
    exact: true,
    component: () => import("@/views/FormLeads.vue"),
    meta: {
      requiresAuth: true,
      requiresCampaign: true,
    },
  },
  {
    path: "/call-logs",
    name: "call-logs",
    exact: true,
    component: () => import("@/views/CallLeads.vue"),
    meta: {
      requiresAuth: true,
      requiresCampaign: true,
    },
  },
  {
    path: "/reporting/site-performance",
    name: "reporting-site-performance",
    exact: true,
    component: () => import("@/views/reports/SitePerformance.vue"),
    meta: {
      requiresAuth: true,
      requiresCampaign: true,
    },
  },
  {
    path: "/sample",
    name: "sample",
    component: () => import("@/samples/MailingSelectorSample.vue"),
    meta: {
      requiresAuth: true,
      requiresCampaign: true,
    },
  },
  {
    path: "/manage/mailings",
    name: "manage-mailings",
    exact: true,
    component: () => import("@/views/ManageMailings.vue"),
    meta: {
      requiresAuth: true,
      requiresCampaign: true,
    },
  },
  {
    path: "/manage/inbound-phone-numbers",
    name: "manage-inbound-phone-numbers",
    exact: true,
    component: () => import("@/views/ManageInboundPhoneNumbers.vue"),
    meta: {
      requiresAuth: true,
      requiresCampaign: true,
    },
  },
  {
    path: "/manage/campaign-cycles",
    name: "manage-campaign-cylces",
    exact: true,
    component: () => import("@/views/ManageCampaignCycles.vue"),
    meta: {
      requiresAuth: true,
      requiresCampaign: true,
    },
  },
  {
    path: "/change-password",
    name: "change-password",
    exact: true,
    component: () => import("@/views/PasswordChange.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/invite-users",
    name: "invite-users",
    exact: true,
    component: () => import("@/views/InviteUsers.vue"),
    meta: {
      requiresAuth: true,
      requiresCampaign: true,
    },
  },
  {
    path: "/invite/accept/:token",
    name: "accept-invite",
    component: () => import("@/pages/InviteAccept.vue"),
    meta: {
      requiresGuest: true,
    },
  },
  {
    path: "/switch-clients",
    name: "switch-clients",
    component: () => import("@/views/SwitchClients.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/pages/Login.vue"),
    meta: {
      requiresGuest: true,
    },
  },
  {
    path: "/password-reset",
    name: "password-reset-request",
    component: () => import("@/pages/PasswordRequest.vue"),
    meta: {
      requiresGuest: true,
    },
  },
  {
    path: "/password-reset/:token",
    name: "password-reset",
    component: () => import("@/pages/PasswordReset.vue"),
    meta: {
      requiresGuest: true,
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

//##Setup the router
router.beforeEach((to, from, next) => {
  const store = useAuthStore();
  const appStore = useAppStore();
  console.log("Authenticated: " + store.authenticated);
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.authenticated) {
      next({
        name: "login",
      });
    } else if (to.matched.some((record) => record.meta.requiresCampaign)) {
      //if (store.getters["app/currentCampaign"] === null) {
      if (appStore.currentCampaign == null) {
        next({
          name: "select-campaign",
        });
      } else {
        next();
      }
    } else {
      next();
    }
  }
  //end if rqeuiresAuth
  else if (to.matched.some((record) => record.meta.requiresGuest)) {
    console.log("requires-guest");
    if (store.authenticated) {
      next({
        name: "dashboard",
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
