<template>
  <div>
    <Menubar :model="items">
      <template #end>
        <router-link
          :to="{ path: 'select-campaign' }"
          v-if="hasMultipleCampaigns"
          style="text-docration: none"
        >
          <i class="pi pi-replay mr-2" style="color: #777777"></i>
          <span style="color: #777777">Switch Campaigns</span>
        </router-link>
      </template>
    </Menubar>
  </div>
</template>
<script>
import Menubar from "primevue/menubar";
import { useAppStore } from "@/stores/app";
import { useAuthStore } from "@/stores/auth";

export default {
  components: {
    Menubar,
  },
  setup() {
    const appStore = useAppStore();
    const authStore = useAuthStore();
    return { appStore, authStore };
  },
  data() {
    return {
      items: [
        {
          label: "Dashboard",
          icon: "pi pi-fw pi-home",
          to: { name: "dashboard" },
        },
        {
          label: "Contacts",
          icon: "pi pi-fw pi-users",
          items: [
            {
              label: "Responders",
              to: { name: "responders" },
            },
            {
              label: "Form Conversion",
              to: { name: "form-conversions" },
              visible: () => this.showFormConversions,
            },
            {
              label: "Call Logs",
              to: { name: "call-logs" },
              visible: () => this.showCallLogs,
            },
          ],
        },
        // {
        //   label: "Phone Numbers",
        //   icon: "pi pi-fw pi-phone",
        //   items: [
        //     {
        //       label: "Manage Inbound Phone Numbers",
        //       to: { name: "manage-inbound-phone-numbers" },
        //     },
        //   ],
        // },
        {
          label: "Settings",
          icon: "pi pi-fw pi-cog",
          items: [
            {
              label: "Switch Clients",
              to: { name: "switch-clients" },
              visible: () => this.showSwitchClients,
            },
            {
              label: "Change Password",
              to: { name: "change-password" },
            },
            // {
            //   label: "Manage Mailings",
            //   to: { name: "manage-mailings" },
            //   visible: () => this.showManageMailings,
            // },
            // {
            //   label: "Invite Users",
            //   to: { name: "invite-users" },
            //   visible: () => this.showInviteUsers,
            // },
          ],
        },
      ],
    };
  },
  computed: {
    showFormConversions() {
      return (
        this.appStore.currentCampaign &&
        this.appStore.currentCampaign.form_field_map &&
        JSON.parse(this.appStore.currentCampaign.form_field_map).length > 0
      );
    },
    showCallLogs() {
      return process.env.VUE_APP_SHOW_CALLS === "true";
    },
    showManageMailings() {
      return false;
    },
    showInviteUsers() {
      return false;
    },
    showSwitchClients() {
      //return false;
      return this.$can("clients.switch");
    },
    hasMultipleCampaigns() {
      return (
        this.authStore.availableCampaigns &&
        this.authStore.availableCampaigns.length > 1
      );
    },
  },
};
</script>
